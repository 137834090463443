import { inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { StorageKeysService } from 'app/shared/services/storage-keys.service';

@Injectable({ providedIn: 'root' })
export class IconsService {
    /**
     * Constructor
     */
    constructor(private _storageKeysService: StorageKeysService) {
        const domSanitizer = inject(DomSanitizer);
        const matIconRegistry = inject(MatIconRegistry);
        const VERSION = localStorage.getItem(_storageKeysService.versao) || '0.0.0';
        // Register icon sets
        matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/material-twotone.svg?v=${VERSION}`));
        matIconRegistry.addSvgIconSetInNamespace(`mat_outline`, domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/material-outline.svg?v=${VERSION}`));
        matIconRegistry.addSvgIconSetInNamespace(`mat_solid`, domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/material-solid.svg?v=${VERSION}`));
        matIconRegistry.addSvgIconSetInNamespace(`feather`, domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/feather.svg?v=${VERSION}`));
        matIconRegistry.addSvgIconSetInNamespace(
            `heroicons_outline`,
            domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/heroicons-outline.svg?v=${VERSION}`),
        );
        matIconRegistry.addSvgIconSetInNamespace(
            `heroicons_solid`,
            domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/heroicons-solid.svg?v=${VERSION}`),
        );
        matIconRegistry.addSvgIconSetInNamespace(`heroicons_mini`, domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/heroicons-mini.svg?v=${VERSION}`));
    }
}
