<div class="flex items-center justify-center">
    <span class="font-medium mr-1 capitalize hidden sm:block">{{user?.name}}</span>
    <!-- Button -->
    <button
        mat-icon-button
        [matMenuTriggerFor]="userActions">
        <span class="relative flex items-center justify-center">
            <mat-icon class="icon-size-7" [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        </span>
    </button>

    <mat-menu
        [xPosition]="'before'"
        #userActions="matMenu">
        <button mat-menu-item>
            <span class="flex flex-col leading-none">
                <span>Acessando como</span>
                <span class="mt-1.5 text-md font-medium">{{user.email}}</span>
            </span>
        </button>
        <mat-divider class="my-2"></mat-divider>
        <!-- <button mat-menu-item (click)="toggleSettings()" *ngIf="user?.function?.is_admin">
            <mat-icon [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
            <span>Configurações</span>
        </button> -->
        <button
            mat-menu-item
            (click)="signOut()">
            <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
            <span>Sair</span>
        </button>
    </mat-menu>
</div>
