import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class StorageKeysService {
    private readonly appPrefix: string;
    private readonly abbreviations: { [key: string]: string } = {
        token: 't',
        tokenRefresh: 'tr',
        tokenExpires: 'te',
        userData: 'ud',
        config: 'cfg',
        versao: 'v',
        scheme: 's',
        rememberMe: 'rm',
    };

    constructor(appName: string) {
        this.appPrefix = `${appName.toLowerCase()}_`;
    }

    private generateKey(suffix: string): string {
        const abbreviation = this.abbreviations[suffix] || suffix.substring(0, 3);
        return `${this.appPrefix}${abbreviation}`;
    }

    // Métodos específicos para cada chave
    get token(): string {
        return this.generateKey('token');
    }

    get tokenRefresh(): string {
        return this.generateKey('tokenRefresh');
    }

    get userData(): string {
        return this.generateKey('userData');
    }

    get config(): string {
        return this.generateKey('config');
    }

    get versao(): string {
        return this.generateKey('versao');
    }

    get tokenExpires(): string {
        return this.generateKey('tokenExpires');
    }

    get scheme(): string {
        return this.generateKey('scheme');
    }

    get rememberMe(): string {
        return this.generateKey('rememberMe');
    }

    // Método genérico para casos adicionais
    customKey(key: string, abbreviation?: string): string {
        if (abbreviation) {
            return `${this.appPrefix}${abbreviation}`;
        }
        return this.generateKey(key);
    }
}
