<!-- Loading bar -->
<devstroupe-loading-bar></devstroupe-loading-bar>

<!-- Navigation -->
<devstroupe-vertical-navigation
    class="dark print:hidden"
    [ngClass]="config.class"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigationData"
    [opened]="!isScreenSmall"
>
    <!-- Navigation header hook -->
    <ng-container devstroupeVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex justify-center items-center h-20 p-6 pb-0 cursor-pointer" routerLink="/">
            <!-- Light version -->
            <img class="dark:hidden w-30 mb-5" [src]="config.logo" alt="Logo image" />
            <!-- Dark version -->
            <img class="hidden dark:flex w-30 mb-5" [src]="config.logo" alt="Logo image" />
        </div>
    </ng-container>

    <ng-container devstroupeVerticalNavigationContentBody>
        <div class="w-full bg-gray-100 px-6 py-8 p-4 flex flex-col gap-4 items-center justify-start h-screen overflow-auto">
            <!-- Links -->
            <ng-container *ngFor="let item of menuItems">
                <button
                    *ngIf="!item.validate || item.validate()"
                    [routerLink]="[item.link]"
                    [attr.aria-label]="item.label"
                    class="flex items-center justify-start h-12 w-full rounded-lg overflow-hidden transition-all duration-300 shadow hover:shadow-lg p-2"
                    [ngStyle]="hovered === item.id ? { 'background-color': item.hoverColor } : { 'background-color': 'white' }"
                    (mouseenter)="hovered = item.id"
                    (mouseleave)="hovered = ''"
                >
                    <img
                        *ngIf="hovered !== item.id"
                        [src]="item.image"
                        [alt]="item.label + ' Image'"
                        @fadeInRight
                        class="h-full object-contain transition-all duration-300 w-auto opacity-100"
                        [class.opacity-0]="hovered === item.id"
                    />
                    <span
                        *ngIf="hovered === item.id"
                        @fadeInLeft
                        class="text-xl text-center font-medium transition-all duration-300 opacity-100 w-full"
                        [ngClass]="item.textColor"
                    >
                        {{ item.label }}
                    </span>
                </button>
            </ng-container>
        </div>
    </ng-container>
    <ng-container devstroupeVerticalNavigationFooter>
        <div class="relative flex flex-0 items-center justify-center w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
            <span class="font-medium text-primary-50">Trevo &copy; desde 06/2024 </span>
        </div>
    </ng-container>
</devstroupe-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
    <div class="relative bg-primary-500 flex flex-0 items-center w-full h-20 px-2 z-49 dark:bg-transparent print:hidden">
        <div class="flex items-center pr-2 space-x-2">
            <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:bars-3'" class="text-white"></mat-icon>
            </button>
            <mat-form-field *ngIf="searchEnabled" class="devstroupe-mat-dense min-w-64 search-input" [subscriptSizing]="'dynamic'">
                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
                <input matInput [formControl]="searchInputControl" [autocomplete]="'off'" [placeholder]="'O que você procura?'" />
            </mat-form-field>
        </div>
        <!--data hora atual do sistema -->

        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!-- <devstroupe-fullscreen class="hidden md:block"></devstroupe-fullscreen> -->
            <user [isHome]="true"></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto bg-slate-50">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!-- <div class="relative flex flex-0 items-center justify-center w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Trevo &copy; desde 06/2024 </span>
    </div> -->
</div>
