<!-- Loading bar -->
<devstroupe-loading-bar></devstroupe-loading-bar>

<!-- Navigation -->
<devstroupe-vertical-navigation
    class="dark print:hidden"
    [ngClass]="config.class"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigationData"
    [opened]="!isScreenSmall"
>
    <!-- Navigation header hook -->
    <ng-container devstroupeVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex justify-center items-center h-20 p-6 pb-0">
            <!-- Light version -->
            <img class="dark:hidden w-30 mb-5" [src]="config.logo" alt="Logo image" />
            <!-- Dark version -->
            <img class="hidden dark:flex w-30 mb-5" [src]="config.logo" alt="Logo image" />
        </div>
    </ng-container>

    <ng-container devstroupeVerticalNavigationFooter>
        <div class="relative flex flex-0 items-center justify-center w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
            <span class="font-medium text-primary-50">Trevo &copy; desde 06/2024 </span>
        </div>
    </ng-container>
</devstroupe-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
    <!-- Header -->
    <div class="flex items-center justify-center text- font-semibold mt-1">
        {{ currentDateTime | date : 'dd/MM/yyyy HH:mm:ss' }}
    </div>
    <div class="relative flex flex-0 items-center w-full h-16 px-2 z-49 bg-default dark:bg-transparent print:hidden">
        <div class="flex items-center pr-2 space-x-2">
            <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
            </button>
            <mat-form-field class="devstroupe-mat-dense min-w-64 search-input" [subscriptSizing]="'dynamic'">
                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
                <input matInput [formControl]="searchInputControl" [autocomplete]="'off'" [placeholder]="'O que você procura?'" />
                <!-- <input matInput [formControl]="searchInputControl" [autocomplete]="'off'" [placeholder]="'Pesquisar...'"> -->
            </mat-form-field>
        </div>
        <!--data hora atual do sistema -->

        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!-- <devstroupe-fullscreen class="hidden md:block"></devstroupe-fullscreen> -->
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!-- <div class="relative flex flex-0 items-center justify-center w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Trevo &copy; desde 06/2024 </span>
    </div> -->
</div>
