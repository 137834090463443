import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { companyResolver } from './shared/resolvers/company.resolver';
import { UserTypeGuard } from './core/auth/guards/user-type.guard';
import { typeCustomerGuard } from './core/auth/guards/user-type-customer.guard';
import { FunctionGuard } from './core/auth/guards/function.guard';
import { stat } from 'fs';
import { PecGuard } from './core/auth/guards/pec.guard';
import { InactivityGuard } from './core/auth/guards/inactivity.guard';

const PECConfig = () => ({
    logo: 'assets/images/logo/pec_dark.png',
    class: 'bg-modules-pec text-gray-800',
});
const BIConfig = () => ({
    logo: 'assets/images/logo/bi_white.png',
    class: 'bg-modules-bi',
});
const TIPConfig = () => ({
    logo: 'assets/images/logo/tip_white.png',
    class: 'bg-modules-tip',
});
const PCPConfig = () => ({
    logo: 'assets/images/logo/pcp_white.png',
    class: 'bg-modules-pcp',
});

const dataPEC = {
    module: 'pec',
};
const dataBI = {
    module: 'bi',
};
const dataTIP = {
    module: 'tip',
};

const dataPCP = {
    module: 'pcp',
};

const ajudaPath = {
    path: 'ajuda',
    data: {
        ...dataTIP,
    },
    loadChildren: () => import('app/modules/tip/topic/ajuda.routes'),
};
// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    // { path: '', pathMatch: 'full', redirectTo: 'pcp/ordens-producao' },
    { path: '', pathMatch: 'full', redirectTo: 'home' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    // { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'pcp/ordens-producao' },
    { path: 'not-found-company', loadChildren: () => import('app/modules/auth/not-found-company/not-found-company.routes') },
    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes') },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes') },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes') },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes') },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes') },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes') },
            { path: 'change-password', loadChildren: () => import('app/modules/auth/change-password/change-password.routes') },
            { path: 'loja-redirect', loadChildren: () => import('app/modules/auth/loja-redirect/loja-redirect.routes') },
            // { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes') },
        ],
    },
    {
        path: 'home',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'home',
        },
        children: [
            { path: '', loadChildren: () => import('app/modules/home/helper-module/helper-module.routes') },
            {
                path: 'usuarios',
                data: {
                    // ...dataPCP,
                },
                loadChildren: () => import('app/modules/home/user/user.routes'),
            },
            {
                path: 'funcoes',
                data: {
                    // ...dataPCP,
                },
                loadChildren: () => import('app/modules/pcp/function/function.routes'),
            },
            {
                path: 'banners',
                data: {},
                loadChildren: () => import('app/modules/home/home-banners/home-banners.routes'),
            },
            {
                path: 'holidays',
                data: {},
                loadChildren: () => import('app/modules/home/holiday/holiday.routes'),
            },
            ajudaPath,
            // { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes') },
        ],
    },

    // Admin routes
    {
        path: 'pcp',
        canActivate: [AuthGuard, UserTypeGuard, FunctionGuard, InactivityGuard],
        canActivateChild: [AuthGuard, UserTypeGuard, FunctionGuard, InactivityGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
            config: PCPConfig,
        },
        data: {
            ...dataPCP,
            type: ['USER'],
            functions: ['pcp_enabled'],
        },
        children: [
            {
                path: 'usuarios',
                data: {
                    ...dataPCP,
                },
                loadChildren: () => import('app/modules/home/user/user.routes'),
            },
            {
                path: 'funcoes',
                data: {
                    ...dataPCP,
                },
                loadChildren: () => import('app/modules/pcp/function/function.routes'),
            },
            {
                path: 'status',
                data: {
                    ...dataPCP,
                },
                loadChildren: () => import('app/modules/pcp/status/status.routes'),
            },
            {
                path: 'status_art',
                data: {
                    ...dataPCP,
                },
                loadChildren: () => import('app/modules/pcp/status-art/status-art.routes'),
            },
            {
                path: 'motivos-perda',
                data: {
                    ...dataPCP,
                },
                loadChildren: () => import('app/modules/pcp/reason-loss/reason-loss.routes'),
            },
            {
                path: 'ordens-producao',
                data: {
                    ...dataPCP,
                },
                loadChildren: () => import('app/modules/pcp/production-order/production-order.routes'),
            },
            {
                path: 'fundo-investimento',
                data: {
                    ...dataPCP,
                },
                loadChildren: () => import('app/modules/pcp/investment-fund/investment-fund.routes'),
            },
            {
                path: 'empresas',
                data: {
                    ...dataPCP,
                },
                loadChildren: () => import('app/modules/pec/company/company.routes'),
            },
            {
                path: 'tipo_de_anexo',
                data: {
                    ...dataPCP,
                },
                loadChildren: () => import('app/modules/pcp/type-order-files/type-order-files.routes'),
            },
            ajudaPath,
        ],
    },
    //PEC
    {
        path: 'pec',
        canActivate: [AuthGuard, PecGuard, InactivityGuard],
        canActivateChild: [AuthGuard, PecGuard, InactivityGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
            config: PECConfig,
        },
        data: {
            ...dataPEC,
            // functions: ['pec_enabled'],
        },
        children: [
            {
                path: 'usuarios',
                canActivateChild: [typeCustomerGuard],
                canActivate: [typeCustomerGuard],
                data: {
                    ...dataPEC,
                    type_customer: ['matriz', 'gestor'],
                },
                loadChildren: () => import('app/modules/home/user/user.routes'),
            },
            {
                path: 'empresas',
                data: {
                    ...dataPEC,
                },
                loadChildren: () => import('app/modules/pec/company/company.routes'),
            },
            {
                path: 'produtos',
                data: {
                    ...dataPEC,
                },
                loadChildren: () => import('app/modules/pec/product-company/product-company.routes'),
            },
            {
                path: 'relatorios',
                data: {
                    ...dataPEC,
                },
                loadChildren: () => import('app/modules/pec/relatorios/relatorio.routes'),
            },
            {
                path: 'pedidos',
                data: {
                    ...dataPEC,
                },
                loadChildren: () => import('app/modules/pec/order_product_user/order_product_user.routes'),
            },
            ajudaPath,
        ],
    },
    //BI
    {
        path: 'bi',
        canActivate: [AuthGuard, FunctionGuard, InactivityGuard],
        canActivateChild: [AuthGuard, FunctionGuard, InactivityGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
            config: BIConfig,
        },
        data: {
            ...dataBI,
            functions: ['bi_enabled'],
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'relatorios',
            },
            {
                path: 'relatorios',
                data: {
                    ...dataBI,
                },
                loadChildren: () => import('app/modules/bi/iframe/iframe.routes'),
            },
            ajudaPath,
        ],
    },
    //TIP
    {
        path: 'tip',
        canActivate: [AuthGuard, InactivityGuard],
        canActivateChild: [AuthGuard, InactivityGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
            config: TIPConfig,
        },
        data: {
            ...dataTIP,
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'ajuda',
            },
            {
                path: 'topic',
                data: {
                    ...dataTIP,
                },
                loadChildren: () => import('app/modules/tip/topic/topic.routes'),
            },
            {
                path: 'category',
                data: {
                    ...dataTIP,
                },
                loadChildren: () => import('app/modules/tip/topic_category/topic_category.routes'),
            },
            ajudaPath,
        ],
    },
    //PEC ecommerce routes
    {
        path: ':company_slug',
        canActivate: [AuthGuard, InactivityGuard],
        component: LayoutComponent, //hack pra ler o css do layout
        data: {
            layout: 'ecommerce',
            functions: ['pec_enabled'],
        },
        canActivateChild: [AuthGuard],
        resolve: {
            initialData: initialDataResolver,
            company: companyResolver,
        },
        // children: [
        //     {
        //         path: '',
        //         component: LayoutEcommerceComponent, //hack pra ler o css do layout
        //     },
        // ],
        children: [{ path: '', loadChildren: () => import('app/modules/pec/ecommerce/ecommerce.routes') }],
    },
];
