import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { ApiService } from 'app/shared/apis/api.service';
import { StorageKeysService } from 'app/shared/services/storage-keys.service';
import { catchError, Observable, switchMap, throwError, map } from 'rxjs';
let versaoJWT;
/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const authService = inject(AuthService);
    const apiService = inject(ApiService);
    const _storageKeysService = inject(StorageKeysService);

    // Clone the request object
    let newReq = req.clone();

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    let isAuthenticated = authService.token && !AuthUtils.isTokenExpired(authService.token);

    if (isAuthenticated) {
        newReq = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + authService.token),
        });
        // } else if (authService.token && authService.tokenRefresh && authService.rememberMe == "true") {
    } else if (authService.token && authService.tokenRefresh) {
        let getEndPoint = newReq.url.split('/')[newReq.url.split('/').length - 1];

        if (getEndPoint.includes('refreshToken')) {
            newReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + authService.tokenRefresh),
            });
        } else {
            return apiService.postSub('auth/refreshToken', {}).pipe(
                switchMap((res: { token: string; refreshToken: string }) => {
                    const { token, refreshToken } = res;

                    if (token) {
                        authService.token = token;
                    }

                    authService.tokenRefresh = refreshToken;

                    newReq = req.clone({
                        headers: req.headers.set('Authorization', 'Bearer ' + authService.token),
                    });

                    return next(newReq).pipe(
                        catchError((error) => {
                            return erroHandle(error);
                        }),
                    );
                }),
                catchError((error) => {
                    return erroHandle(error);
                }),
            );
        }
    }

    // Response
    return next(newReq).pipe(
        map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                if (event.headers.get('versao')) {
                    versaoJWT = JSON.stringify(event.headers.get('versao'));

                    const versao = localStorage.getItem(_storageKeysService.versao);
                    if (versao && versao != JSON.stringify(event.headers.get('versao')) && !newReq.url.includes('login')) {
                        // this.staticshowNotificacaoVersao();
                        localStorage.setItem(_storageKeysService.versao, JSON.stringify(event.headers.get('versao')));
                        location.reload();
                    } else if (!versao) {
                        localStorage.setItem(_storageKeysService.versao, JSON.stringify(event.headers.get('versao')));
                        location.reload();
                    }
                }
            }
            return event;
        }),
        catchError((error) => {
            return erroHandle(error);
        }),
    );
    function erroHandle(error) {
        // Catch "401 Unauthorized" responses
        if (error instanceof HttpErrorResponse && ((error.status === 401 && !error.url.includes('auth/login')) || error.url.includes('refreshToken'))) {
            // Sign out
            authService.logout();

            // Reload the app
            location.reload();
        }

        return throwError(error);
    }
};
