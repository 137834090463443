<div class="flex flex-col flex-auto w-full min-w-0">
    <div class="bg-yellow-400 p-4 z-10 flex justify-between items-center sticky top-0 w-full">
        <!-- Espaço vazio para garantir que a logo fique centralizada -->
        <div class="w-1/3"></div>

        <!-- Logo do site -->
        <div class="flex justify-center w-1/3">
            <img src="assets/images/logo/iconlogo-evo-brindes-preto.svg" class="h-8" />
        </div>

        <!-- Carrinho e Botão de Logout -->
        <div class="w-1/3 flex justify-end items-center space-x-4">
            <!-- Ícone do Carrinho -->
            <div class="flex flex-row items-center gap-2" (click)="goToCart()">
                <span>{{ currencyCart | currency : 'BRL' }}</span>
                <mat-icon svgIcon="heroicons_solid:shopping-cart" [matBadge]="countItems"></mat-icon>
            </div>

            <!-- Botão de Logout -->
            <!-- <button class="bg-white text-black px-4 py-2 rounded-lg" (click)="logout()">Logout</button> -->
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="goToRelatorios()">
                    <mat-icon>picture_as_pdf</mat-icon>
                    <span>Relatórios</span>
                </button>

                <button mat-menu-item (click)="logout()">
                    <mat-icon>logout</mat-icon>
                    <span>Sair</span>
                </button>
            </mat-menu>
        </div>
    </div>

    <div class="flex flex-col flex-auto">
        <router-outlet></router-outlet>
    </div>
</div>
