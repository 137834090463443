import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, first, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { UserModel } from 'app/modules/home/user/user.model';
import { environment } from 'environments/environment';
import jwt_decode from 'jwt-decode';
import { CompanyService } from 'app/modules/pec/company/company.service';
import { StorageKeysService } from '../services/storage-keys.service';

@Injectable({ providedIn: 'root' })
export class ApiService {
    static user: BehaviorSubject<UserModel> = new BehaviorSubject(null);

    encryptSecretREQey = 'INFO#$%info345';

    private url: string = environment.apiUrl;
    public constructor(
        public http: HttpClient,
        public _router: Router,
        public httpClient: HttpClient,
        public back: HttpBackend,
        private _storageKeysService: StorageKeysService,
    ) {
        this.httpClient = new HttpClient(back);

        ApiService.userNext(localStorage.getItem(_storageKeysService.token) ? jwt_decode(localStorage.getItem(_storageKeysService.token))['user'] : null);
    }

    static get userCurrent(): Observable<UserModel> {
        return this.user.asObservable();
    }

    static userNext(valor: UserModel) {
        if (valor?.company) {
            CompanyService.setCompany(valor?.company);
        }

        return this.user.next(valor);
    }
    decode() {
        let decode: any = localStorage.getItem(this._storageKeysService.token);
        if (decode) {
            return decode;
        } else {
            return;
        }
    }

    login(auth) {
        return this.http.post(environment.apiUrl + '/user/login', auth).toPromise();
    }

    register(user) {
        return this.http.post(environment.apiUrl + '/user/create', user).toPromise();
    }

    // logout() {
    //     ApiService.user.next(null);

    //     const version = localStorage.getItem('version');

    //     localStorage.clear();

    //     if (version) {
    //         localStorage.setItem('version', version);
    //     }

    //     this._router.navigate(['compact/account/login']);
    // }

    public getSub<RES = any>(endpoint: string, params?: any, reqOpts?: any) {
        if (!reqOpts) {
            reqOpts = {
                params: new HttpParams(),
            };
        }

        if (params) {
            reqOpts.params = new HttpParams();
            for (let k in params) {
                if (params[k] != undefined && params[k] != null) {
                    reqOpts.params = reqOpts.params.set(k, params[k]);
                }
            }
        }

        return this.http.get<RES>(this.url + '/' + endpoint, reqOpts) as Observable<unknown> as Observable<RES>;
    }

    public get<RES = any, REQ = any>(endpoint: string, params?: REQ, reqOpts?: any): Promise<RES> {
        if (!reqOpts) {
            reqOpts = {
                params: new HttpParams(),
            };
        }

        if (params) {
            reqOpts.params = new HttpParams();
            for (let k in params) {
                if (params[k]) {
                    reqOpts.params = reqOpts.params.set(k, params[k]);
                }
            }
        }

        return this.http.get(this.url + '/' + endpoint, reqOpts).toPromise() as Promise<RES | any>;
    }

    // public post<RES=any,REQ=any>(endpoint: string, body: REQ, reqOpts?: any): Promise<RES> {
    //     return this.http
    //         .post(this.url + '/' + endpoint, body, reqOpts)
    //         .toPromise() as Promise<RES|any>;
    // }

    public postSub<RES = any, REQ = any>(endpoint: string, body: REQ, reqOpts?: any): Observable<RES> {
        return this.http.post<RES>(this.url + '/' + endpoint, body, reqOpts).pipe(first()) as Observable<RES>;
    }

    public put<RES = any>(endpoint: string, body: any, reqOpts?: any): Promise<RES> {
        return this.http.put(this.url + '/' + endpoint, body, reqOpts).toPromise() as Promise<RES | any>;
    }

    public putSub<RES = any>(endpoint: string, body: any, reqOpts?: any): Observable<RES> {
        return this.http.put(this.url + '/' + endpoint, body, reqOpts) as Observable<unknown> as Observable<RES>;
    }

    public delete<RES = any>(endpoint: string, reqOpts?: any): Promise<RES> {
        return this.http.delete(this.url + '/' + endpoint, reqOpts).toPromise() as Promise<RES | any>;
    }

    public deleteSub<RES = any>(endpoint: string, reqOpts?: any): Observable<RES> {
        return this.http.delete<RES>(this.url + '/' + endpoint, reqOpts).pipe(first()) as Observable<unknown> as Observable<RES>;
    }

    public patch(endpoint: string, body: any, reqOpts?: any) {
        return this.http.patch(this.url + '/' + endpoint, body, reqOpts).toPromise();
    }

    public upload(url: string, body: any, reqOpts?: any) {
        return this.http.post(url, body, reqOpts).toPromise();
    }

    public customGet(endpoint: string) {
        return this.httpClient.get(endpoint).toPromise();
    }

    public customGetSub(endpoint: string) {
        return this.httpClient.get(endpoint);
    }

    postEncr(endpoint: string, body: any, reqOpts?: any) {
        return this.http.post(this.url + '/' + endpoint, { cryp: this.encryptData(body) }, reqOpts).toPromise();
    }

    encryptData(data) {
        try {
            return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecretREQey).toString();
        } catch (e) {
            console.error(e);
        }
    }

    decryptData(data) {
        try {
            const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretREQey);
            if (bytes.toString()) {
                return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            }
            return data;
        } catch (e) {
            console.error(e);
        }
    }

    async createRESokenCode() {
        return [4, 2, 2, 2, 6] // or 8-4-4-4-12 in hex
            .map((group) => CryptoJS.lib.WordArray.random(group))
            .join('-');
    }
}
